import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useParams } from 'react-router-dom'
import { queryOrderDataNoAuth } from '../api'
import CircularProgress from '@mui/material/CircularProgress'
import setOrderState from '../../work-orders/components/work-order-detail/components/scheduling-modal/container-modal/utils'
import { fetchData } from '../../global/utils/fetch'
import CustomerScheduler from './customer-scheduler/CustomerScheduler'
import ErrorScreen from './error-screen/ErrorScreen'
import { IOrder } from '../../../../app/entities/Order'
import {
  ISchedulerStateProps,
  initialStateProps,
} from '../../schedule/components/schedule-modal/components/container-modal/initialData'
import { STATUSES } from '../../global/constants/order-status'
import { MODES } from '../../global/constants/scheduler'

interface ICustomerSchedulerMainProps {
  mode: MODES
}

const SCHEDULABLE_STATUSES = [
  STATUSES.en_route,
  STATUSES.scheduled,
  STATUSES.reschedule,
  STATUSES.quote,
]

// specific to this file for quick debugging
const QUERY_ORDER_DATA_ERRORS = {
  ORDER_NOT_FOUND: 'order not found',
  ORDER_NOT_IN_SCHDULABLE_STATUS: `order not in a schedulable status: ${SCHEDULABLE_STATUSES.join(', ')}`,
}

export default function CustomerSchedulerMain(
  props: ICustomerSchedulerMainProps,
) {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true) //preset to true so that the customerScheduler is not displayed with null values for the schedulerState
  const [error, setError] = useState<string | undefined>(undefined)
  const [scheduleOrderState, setScheduleOrderState] =
    useState<ISchedulerStateProps>({
      ...initialStateProps,
      orderId: id ?? '',
    })

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true)
        const orderData: IOrder = await fetchData<IOrder>(
          queryOrderDataNoAuth(id),
        )
        const orderNotFound = Array.isArray(orderData) && orderData.length === 0
        const shouldDisplayErrorScreen = !SCHEDULABLE_STATUSES.includes(
          orderData.status ?? '',
        )
        if (orderNotFound) {
          //returns 204 empty array if order not found
          setError(QUERY_ORDER_DATA_ERRORS.ORDER_NOT_FOUND)
        } else if (shouldDisplayErrorScreen) {
          setError(QUERY_ORDER_DATA_ERRORS.ORDER_NOT_IN_SCHDULABLE_STATUS)
        } else {
          setScheduleOrderState(setOrderState(orderData))
        }
      } catch (err: any) {
        console.error(err)
        setError(JSON.stringify(error))
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
  }, [id])

  useEffect(() => {
    error && console.error(error)
  }, [error])

  return (
    <>
      {isLoading ? (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      ) : Boolean(error) ? (
        <ErrorScreen />
      ) : (
        <CustomerScheduler
          schedulerState={scheduleOrderState}
          setSchedulerState={setScheduleOrderState}
          mode={props.mode}
        />
      )}
    </>
  )
}
